.header {
  background-image: url("https://ik.imagekit.io/44d0h1g5ht2/atoerhr-img/bannerbg%20(1)_-cE7N4Xj5.jpg?updatedAt=1722761532662");
  padding: 5%;
  height: 100%;
}

.link-button {
  height: 45px;
  border: none;
  border-radius: 10px;
  background-color: #5f7af7;
  color: white;
  cursor: pointer;
  box-shadow: 0 3px 7px 1px #00000040;
  transition: background-color 0.1s ease-out;
  padding: 10px;
  text-decoration: none;
}

.logo {
  width: 12%;
}

.banner {
  width: 85%;
}

.intro {
  background-color: #f4f5f8;
  padding: 5%;
}

.intro-img {
  width: 85%;
}

.intro-text {
  font-size: 60px;
}

.info {
  padding: 5%;
}

.info-title {
  color: "#5f7af7";
  text-align: "center";
  font-size: 60px;
}

.info-column {
  background-color: #f4f5f8;
  margin: 1%;
  padding: 5%;
  border-radius: 15px;
}

.info-column-disburse-icon {
  width: 100%;
}

.info-column-bill-icon {
  width: 100%;
}

.benefit {
  padding: 5%;
}

.benefit-title {
  font-size: 60px;
  color: #5f7af7;
}

.benefit-img {
  width: 85%;
}

.benefit-row {
  margin: 0 auto;
}

.footer {
  padding: 5%;
}

.logo-footer {
  width: 20%;
}

@media (max-width: 500px) {
  .header {
    padding: 6% 3%;
  }

  .logo {
    width: 30%;
  }

  .banner {
    min-width: 300px;
  }

  .intro-text {
    font-size: 30px;
  }

  .info-title {
    font-size: 30px;
  }

  .intro-img {
    min-width: 300px;
  }

  .info-column {
    min-width: 300px;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .benefit-title {
    font-size: 30px;
  }

  .benefit-img {
    min-width: 300px;
  }

  .benefit-img-col {
    text-align: center;
  }

  .footer-col {
    min-width: 200px;
  }

  .logo-footer {
    width: 40%;
  }
}

@media (max-width: 400px) {
  .banner {
    min-width: 250px;
  }

  .intro-img {
    min-width: 250px;
  }
}

@media (max-width: 300px) {
  .banner {
    min-width: 125px;
  }
}
